import React, { useState } from "react";
import {Link} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
//import LoginValidation from './LoginValidation';

const styles = {
    section: {
     color: 'red'
    }
}

function Login () {

    // const formLogin = () => {

    //     console.log("Callback function when form is submitted!");
    //     console.log("Form Values ", values);
    //   }
    
    //   //Custom hook call
    //   const {handleChange, values,errors,handleSubmit} = LoginValidation(formLogin);
    const history = useHistory();

    const [loginInput, setLogin] = useState({

        email: '',
        password:'',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {

        e.preventDefault();
        
        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }

        axios.defaults.withCredentials = true;

          axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/login`, data).then(res => {

                if(res.data.status === 200){

                    localStorage.setItem('auth_token', res.data.token);
					localStorage.setItem('auth_name', res.data.name);
                    swal("Success", res.data.message, "success");

                    if(res.data.role === 1){

                        history.push('/admin/dashboard');
                    }
                    else 
                    {
                        history.push('/admin/staffdashboard');
                    }

                }else if(res.data.status === 401){

                    swal("Warning", res.data.message, "warning");

                }else{
                    setLogin({...loginInput, error_list: res.data.validation_errors });
                }
            });
         });
    }


    return (

        <div>
            <div className="main-wrapper">
                <div className="account-content">
                    <div className="container">
                        
                        <div className="account-logo">
                            <img src="/dnb.png" alt="Company Logo" style={{
                                    width: 200,
                                    height: 100,
                                    }}/>
                        </div>
                        
                        <div className="account-box">
                            <div className="account-wrapper">
                                <h3 className="account-title">Admin Login</h3>
                                
                                <form onSubmit={loginSubmit}>
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <input className="form-control" minLength='4' name="email"  type="text" onChange={handleInput} value={loginInput.email} />
                                        {/* {errors.username && <h5 style={styles.section}>{errors.username}</h5>} */}
                                        <span style={styles.section}>{loginInput.error_list.email}</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <label>Password</label>
                                            </div>
                                        </div>
                                        <input className="form-control" minLength='8' name="password"  type="password" onChange={handleInput} value={loginInput.password} />
                                        <span style={styles.section}>{loginInput.error_list.password}</span>
                                        {/* {errors.password && <h5 style={styles.section}>{errors.password}</h5>} */}
                                    </div>
                                    
                                    <div className="form-group text-left">
                                        <button className="btn btn-primary account-btn" name="login" type="submit">Login</button>
                                            <div className="col-auto pt-2">
                                                <Link className="text-muted float-right" to="ForgotPassword">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                    </div>
                                </form>
                                    
                            </div>
                        </div>
                    </div>
                </div>
		    </div>
        </div>
    );
}

export default Login;