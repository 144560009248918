import React from "react";

import '../../assets/admin/assets/css/bootstrap.min.css';
import '../../assets/admin/assets/css/style.css';
import '../../assets/admin/assets/css/dataTables.bootstrap4.min.css';
import '../../assets/admin/assets/css/select2.min.css';
import '../../assets/admin/assets/css/font-awesome.min.css';
import '../../assets/admin/assets/css/line-awesome.min.css'; 

import Navbar from './Navbar';
import Sidebar from "./Sidebar";

import routes from '../../routes/routes';
import { Switch, Route, Redirect } from "react-router-dom";

const MasterLayout = () => {

    return (
        
            <div className="main-wrapper">
                <Navbar />
                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        <Sidebar />
                    </div>
                
                    <div id="layoutSidenav_content">
                        <main>
                            <Switch>
                            {routes.map((route, idx) => {

                                        return (
                                            route.component && (
                                                <Route 
                                                key={idx} 
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={(props) => (
                                                    <route.component {...props} />
                                                )} />
                                            )
                                        );
                                    })}

                                <Redirect from="admin" to="/admin/dashboard" />
                            </Switch>
                        </main>
                        
                    </div>
                </div>
            </div>
    );
}

export default MasterLayout;