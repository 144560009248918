import React, { useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const styles = {
    section: {
     color: 'red'
    }
}

function AddUser() {

	const [modal, setmodal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [sourceModal, setsourceModal] = useState(false);
	const [designationModal, setdesignationModal] = useState(false);
	const [roleModal, setroleModal] = useState(false);
	const [educationModal, seteducationModal] = useState(false);
	const [bankModal, setbankModal] = useState(false);
	
	// Show/Hide Textbox

	const [showhide, setShowhide]= useState('');
  
	const history = useHistory();

	//Department Dropdown
	
	const [departmentData, setdepartment] = useState([]);

	useEffect(function () {
		axios
		.get('/api/departmentlist')
		.then((response) => setdepartment(response.data.department))
		.then((error) => console.log(error));
	}, []);

	//Marital Dropdown

	const getInitialState = () => {
		const valuee = "Unmarried";
		return valuee;
	  };
	
	  const [valuee, setValue] = useState(getInitialState);
	
	  const handleshowhide = (e) => {
		const getuser = e.target.value;    
		setShowhide(getuser);
	  }

	  const handleChange = (e) => {
		//const getdate = e.target.value;    
	  	// setShowhide(e.target.value);
		setValue(e.target.value);
        setAddUser({...AddUserInput, [e.target.name] : e.target.value });
	  };

	  const commonHandleChange = (e) => {
		handleshowhide(e);
		handleChange();
	  }
	//Marital Dropdown

	//Shift Dropdown

	const [shiftData, setshiftlist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/shiftlist')
		.then((response) => setshiftlist(response.data.shift))
		.then((error) => console.log(error));
	}, []);

	//Source DropDown

	const [sourceData, setsourcelist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/sourcelist')
		.then((response) => setsourcelist(response.data.source))
		.then((error) => console.log(error));
	}, []);


	// Designation Dropdown

	const [designationData, setdesignationlist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/designationlist')
		.then((response) => setdesignationlist(response.data.designation))
		.then((error) => console.log(error));
	}, []);

	// Highest Education Qualification Dropdown

	const [educationData, seteducationlist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/educationlist')
		.then((response) => seteducationlist(response.data.education))
		.then((error) => console.log(error));
	}, []);

	// Bank Name Dropdown

	const [bankData, setbanklist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/banklist')
		.then((response) => setbanklist(response.data.bank))
		.then((error) => console.log(error));
	}, []);

	// Reporting manager Dropdown

	const [reportingManagerData, setreportingManagerlist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/managerlist')
		.then((response) => setreportingManagerlist(response.data.manager))
		.then((error) => console.log(error));
	}, []);

	// Roles Dropdown

	const [rolesData, setroleslist] = useState([]);
 
	useEffect(function () {
		axios
		.get('/api/roleslist')
		.then((response) => setroleslist(response.data.roles))
		.then((error) => console.log(error));
	}, []);

	// Validation 

    // const { register, handleSubmit, formState: { errors }} = useForm(); // initialise the hook
    // const onSubmit = (data) => { console.log(data) } // callback when validation pass

	//Add User

	const [AddUserInput, setAddUser] = useState({
		fullname: '',
		personal_mobile_number: '',
		secondary_mobile_number: '',
		present_address: '',
		permanent_address: '',
		email: '',
		official_email: '',
		date_of_birth:'',
		blood_group:'',
		marital_status: '',
		anniversary_date: '',
		highest_education_qualification: '',
		guardian_name:'',
		guardian_contact_number:'',
		guardian_relationship: '',
		emergency_name: '',
		emergency_contact_number: '',
		source_id: '',
		banck_name: '',
		payee_name: '',
		account_number: '',
		ifsc_code: '',
		name:'',
		date_of_joining: '',
		designation: '',
		department_id:'',
		annual_ctc: '',
		roles:'',
		reporting_manager_id:'',
		shift_id: '',
		attendace_card_no: '',
		error_list: [],
		
	});

	const handleInput = (e) => {
		e.persist();
		setAddUser({...AddUserInput, [e.target.name]: e.target.value });
	}

	const AddUserSubmit = (e) => {

		e.preventDefault();

		const data = {
			
			fullname: 			   AddUserInput.fullname,
			personal_mobile_number: 			   AddUserInput.personal_mobile_number,
			secondary_mobile_number: 			   AddUserInput.secondary_mobile_number,
			present_address: AddUserInput.present_address,
			permanent_address: AddUserInput.permanent_address,
			email: 				   AddUserInput.email,
			official_email: 	   AddUserInput.official_email,
			date_of_birth:				   AddUserInput.date_of_birth,
			blood_group: 		   AddUserInput.blood_group,
			marital_status: 		   AddUserInput.marital_status,
			anniversary_date:		   AddUserInput.anniversary_date,
			highest_education_qualification:			   AddUserInput.highest_education_qualification,
			guardian_name: 		   AddUserInput.guardian_name,
			guardian_contact_number: 		   AddUserInput.guardian_contact_number,
			guardian_relationship: AddUserInput.guardian_relationship,
			emergency_name:		   AddUserInput.emergency_name,
			emergency_contact_number:		   AddUserInput.emergency_contact_number,
			source_id:      		   AddUserInput.source_id,
			banck_name:    		   AddUserInput.banck_name,
			payee_name:			   AddUserInput.payee_name,
			account_number:			   AddUserInput.account_number,
			ifsc_code:       	   AddUserInput.ifsc_code,
			name: 				   AddUserInput.name,
			date_of_joining:				   AddUserInput.date_of_joining,
			designation:		   AddUserInput.designation,
			department_id: 		   AddUserInput.department_id,
			annual_ctc:			   AddUserInput.annual_ctc,
			roles: 				   AddUserInput.roles,
			reporting_manager_id:	   AddUserInput.reporting_manager_id,
			shift_id:				   AddUserInput.shift_id,
			attendace_card_no:			   AddUserInput.attendace_card_no,
		}

		axios.get('/sanctum/csrf-cookie').then(response => {
			axios.post(`/api/adduser`, data).then(res => {

				if(res.data.status === 200)
				{
					localStorage.setItem('auth_token', res.data.token);
					localStorage.setItem('auth_name', res.data.name);
					swal("Success", res.data.message, "success");
					history.push('/admin/users');
				}
				else{
					setAddUser({ ...AddUserInput, error_list: res.data.validation_errors});
				}
			});
		});
	}

	// Add Department Modal

	const [AddDepartmentInput, setDepartment] = useState({
		name:''
	})

	const handleInputDepartment = (e) => {
		e.persist();
		setDepartment({...AddDepartmentInput, [e.target.name]: e.target.value });
	}

	const AddDepartmentSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddDepartmentInput.name,
		}

			axios.post(`/api/add-department`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setDepartment({ ...AddDepartmentInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Shift Modal 

	const [AddShiftInput, setshift] = useState({
		name:''
	})

	const handleInputShift = (e) => {
		e.persist();
		setshift({...AddShiftInput, [e.target.name]: e.target.value });
	}

	const AddShiftSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddShiftInput.name,
		}

			axios.post(`/api/add-shift`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setshift({ ...AddShiftInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Source Modal

	const [AddSourceInput, setsource] = useState({
		name:''
	})

	const handleInputSource = (e) => {
		e.persist();
		setsource({...AddSourceInput, [e.target.name]: e.target.value });
	}

	const AddSourceSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddSourceInput.name,
		}

			axios.post(`/api/add-source`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setsource({ ...AddSourceInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Designation Modal

	const [AddDesignationInput, setDesignation] = useState({
		name:''
	})

	const handleInputDesignation = (e) => {
		e.persist();
		setDesignation({...AddDesignationInput, [e.target.name]: e.target.value });
	}

	const AddDesignationSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddDesignationInput.name,
		}

			axios.post(`/api/add-designation`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setDesignation({ ...AddDesignationInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Education Modal

	const [AddEducationInput, setEducation] = useState({
		name:''
	})

	const handleInputEducation = (e) => {
		e.persist();
		setEducation({...AddEducationInput, [e.target.name]: e.target.value });
	}

	const AddEducationSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddEducationInput.name,
		}

			axios.post(`/api/add-education`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setEducation({ ...AddEducationInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Bank Modal

	const [AddbankInput, setbank] = useState({
		name:''
	})

	const handleInputbank = (e) => {
		e.persist();
		setbank({...AddbankInput, [e.target.name]: e.target.value });
	}

	const AddbankSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddbankInput.name,
		}

			axios.post(`/api/add-bank`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setbank({ ...AddbankInput, error_list: res.data.validation_errors});
				}
			});
	}

	// Add Roles Modal

	const [AddrolesInput, setroles] = useState({
		name:'',
		description:''
	})

	const handleInputroles = (e) => {
		e.persist();
		setroles({...AddrolesInput, [e.target.name]: e.target.value });
	}

	const AddrolesSubmit = (e) => {
		e.preventDefault();

		const data = {
			name: AddrolesInput.name,
			description: AddrolesInput.description,
		}

			axios.post(`/api/add-roles`, data).then(res => {

				if(res.data.status === 200)
				{
					swal("Success", res.data.message, "success");
					window.location.reload(false);
					history.push('/admin/addUser');
				}
				else{
					setroles({ ...AddrolesInput, error_list: res.data.validation_errors});
				}
			});
	}

	const [modalOpen, setModalOpen] = useState(false);
	const Toggle = () => setModalOpen(!modalOpen);

	const { marital_status } = AddUserInput.marital_status;

    return (

        <div className="page-wrapper">
			<Modal size='sm' isOpen={modal} toggle={() => setmodal(!modal)}>
				<ModalHeader toggle={() => setmodal(!modal)}>Add Department</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddDepartmentSubmit}>
									<div className="form-group">
										<label>Department Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputDepartment} value={AddDepartmentInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_department" type="POST" onClick={() => setmodal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

			<Modal size='sm' isOpen={openModal} toggle={() => setOpenModal(!openModal)}>
				<ModalHeader toggle={() => setOpenModal(!openModal)}>Add Shift</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddShiftSubmit}>
									<div className="form-group">
										<label>Shift Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputShift} value={AddShiftInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_shift" type="POST" onClick={() => setOpenModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

			<Modal size='sm' isOpen={sourceModal} toggle={() => setsourceModal(!sourceModal)}>
				<ModalHeader toggle={() => setsourceModal(!sourceModal)}>Add Source</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddSourceSubmit}>
									<div className="form-group">
										<label>Source Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputSource} value={AddSourceInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_source" type="POST" onClick={() => setsourceModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

			<Modal size='sm' isOpen={designationModal} toggle={() => setdesignationModal(!designationModal)}>
				<ModalHeader toggle={() => setdesignationModal(!designationModal)}>Add Designation</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddDesignationSubmit}>
									<div className="form-group">
										<label>Designation Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputDesignation} value={AddDesignationInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_designation" type="POST" onClick={() => setdesignationModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

			<Modal size='ms' isOpen={roleModal} toggle={() => setroleModal(!roleModal)}>
				<ModalHeader toggle={() => setroleModal(!roleModal)}>Add Role</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddrolesSubmit}>
									<div className="form-group">
										<label>Role Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputroles} value={AddrolesInput.name}/>
									</div>
									<div className="form-group">
										<label>Description <span className="text-danger">*</span></label>
										<textarea name="description" rows="5" className="form-control" type="text" onChange={handleInputroles} value={AddrolesInput.description}/>
									</div>
									<div className="submit-section">
										<button name="add_role" type="POST" onClick={() => setroleModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>
		
			<Modal size='ms' isOpen={educationModal} toggle={() => seteducationModal(!educationModal)}>
				<ModalHeader toggle={() => seteducationModal(!educationModal)}>Add Highest Education Qualification</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddEducationSubmit}>
									<div className="form-group">
										<label>Education Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputEducation} value={AddEducationInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_education" type="POST" onClick={() => seteducationModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

			<Modal size='ms' isOpen={bankModal} toggle={() => setbankModal(!bankModal)}>
				<ModalHeader toggle={() => setbankModal(!bankModal)}>Add Bank Name</ModalHeader>
				<ModalBody>
						<div className="modal-content">
							<div className="modal-body">
								<form onSubmit={AddbankSubmit}>
									<div className="form-group">
										<label>Bank Name <span className="text-danger">*</span></label>
										<input name="name" className="form-control" type="text" onChange={handleInputbank} value={AddbankInput.name}/>
									</div>
									<div className="submit-section">
										<button name="add_bank" type="POST" onClick={() => setbankModal(false)} className="btn btn-primary submit-btn">Submit</button>
									</div>
								</form>
							</div>
						</div>	
				</ModalBody>
			</Modal>

        <div className="content container-fluid">
        
            <div className="page-header">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="page-title">Add User</h3>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active"><Link to="/admin/users">Users</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
                        <div style={{  border: '5px', background: '#f2f2f2', padding: '20px' }}>
                            <h3><u>Personal Profile</u></h3><br/>
                            <form onSubmit={AddUserSubmit}>
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Full Name <span className="text-danger">*</span></label>
											<input className="form-control" type="text" name="fullname" onChange={handleInput} value={AddUserInput.fullname}  /> 
											{/* {...register('fullname', { required: true })} */}
                                            {/* {errors.fullname && <p style={styles.section}>Full Name is required.</p>} */}
											<span style={styles.section}>{AddUserInput.error_list.fullname}</span>
										</div>
									</div>
                                </div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Primary Mobile Number <span className="text-danger">*</span></label>
											<input className="form-control" name="personal_mobile_number" onChange={handleInput} value={AddUserInput.personal_mobile_number} type="number" />
											<span style={styles.section}>{AddUserInput.error_list.personal_mobile_number}</span>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Secondary Mobile Number</label>
											<input className="form-control" name="secondary_mobile_number" onChange={handleInput} value={AddUserInput.secondary_mobile_number} type="number" />
											<span style={styles.section}>{AddUserInput.error_list.secondary_mobile_number}</span>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Present Address</label>
											<textarea className="form-control" name="present_address" onChange={handleInput} value={AddUserInput.present_address} type="text" />
											<span style={styles.section}>{AddUserInput.error_list.present_address}</span>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Permanent Address</label>
											<textarea className="form-control" name="permanent_address" onChange={handleInput} value={AddUserInput.permanent_address} type="text" />
											<span style={styles.section}>{AddUserInput.error_list.permanent_address}</span>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Personal Email ID</label>
											<input className="form-control" name="email" type="email" onChange={handleInput} value={AddUserInput.email} />
											<span style={styles.section}>{AddUserInput.error_list.email}</span>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Official Email ID <span className="text-danger">*</span></label>
											<input className="form-control" name="official_email" type="email" onChange={handleInput} value={AddUserInput.official_email} />
											<span style={styles.section}>{AddUserInput.error_list.official_email}</span>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Date Of Birth <span className="text-danger">*</span></label>
											<div className="form-group">
                                                <input type="date" className="form-control" name="date_of_birth" onChange={handleChange} value={AddUserInput.date_of_birth} />
												<span style={styles.section}>{AddUserInput.error_list.date_of_birth}</span>
                                            </div>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Blood Group</label>
											<select className="form-control" name="blood_group" value={AddUserInput.blood_group} onChange={handleChange}>
												<option value="">Select Blood Group</option>
                                                <option value="A+">A+</option>
												<option value="A-">A-</option>
                                                <option value="B+">B+</option>
												<option value="B-">B-</option>
                                                <option value="AB+">AB+</option>
												<option value="AB-">AB-</option>
                                                <option value="O+">O+</option>
												<option value="O-">O-</option>
                                            </select>
											<span style={styles.section}>{AddUserInput.error_list.blood_group}</span>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Marital Status</label>
                                            <select className="form-control" name="marital_status" value={marital_status} onChange={handleshowhide}>
												<option value="">Select Marital Status</option>
                                                <option value="Married">Married</option>
                                                <option value="Unmarried">Unmarried</option>
                                                <option value="Widow">Widow</option>
                                                <option value="Divorce">Divorce</option>
                                            </select>
										</div>
									</div>
										{
											showhide==='Married' && (
											<div className="col-sm-6">
												<div className="form-group">
													<label>Anniversary Date</label>
													<div className="form-group">
														<input type="date" className="form-control" name="anniversary_date" onChange={handleChange} value={AddUserInput.anniversary_date} />
														<span style={styles.section}>{AddUserInput.error_list.anniversary_date}</span>
													</div>
												</div>
											</div>
											)
										}           
                                    {/* <div className="col-sm-6">
										<div className="form-group">
											<label>Anniversary Date</label>
											<div className="form-group">
                                                <input type="date" className="form-control" name="anniversary_date" onChange={handleChange} value={AddUserInput.anniversary_date} />
												<span style={styles.section}>{AddUserInput.error_list.anniversary_date}</span>
                                            </div>
										</div>
									</div> */}
								</div>
                                <div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Highest Education Qualification <span className="text-danger">*</span></label>
											<select className="form-control" name="highest_education_qualification" style={{width: "95%"}} type="text" onChange={handleChange} value={AddUserInput.highest_education_qualification} >
												<option value="0">Select highest Education Qualification </option>
													{
														educationData.map((education) => (
															<option key={education.id}  value={education.id}>
																{education.name}
															</option>
														))
													}
											</select>
											<span style={styles.section}>{AddUserInput.error_list.highest_education_qualification}</span>
										</div>
										<div className="input-group-append" style={{marginTop: "-60px", justifyContent: 'flex-end', marginBottom: '20px' }}>
        									<button type="button" className="btn btn-primary" style={{ height:'42px', width:'46px', color:'black', backgroundColor:'antiquewhite'}} title='Add Highest Education Qualification' onClick={() => seteducationModal(true)}><i className="la la-plus-square"></i></button>
      									</div>
									</div>
                                </div>
                                <div className="row">
									<div className="col-sm-6 col-md-6 col-lg-4">
										<div className="form-group">
											<label>Guardian Name</label>
											<input className="form-control" name="guardian_name" type="text" onChange={handleInput} value={AddUserInput.guardian_name} />
											<span style={styles.section}>{AddUserInput.error_list.guardian_name}</span>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-4">
										<div className="form-group">
											<label>Guardian Contact No</label>
											<input className="form-control" name="guardian_contact_number" type="number" onChange={handleInput} value={AddUserInput.guardian_contact_number} />
											<span style={styles.section}>{AddUserInput.error_list.guardian_contact_number}</span>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-4">
										<div className="form-group">
											<label>Guardian Relationship</label>
											<input className="form-control" name="guardian_relationship" type="text" onChange={handleInput} value={AddUserInput.guardian_relationship} />
											<span style={styles.section}>{AddUserInput.error_list.guardian_relationship}</span>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Emergency Contact Name</label>
											<input className="form-control" name="emergency_name" type="text" onChange={handleInput} value={AddUserInput.emergency_name}/>
											<span style={styles.section}>{AddUserInput.error_list.emergency_name}</span>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Emergency Contact No <span className="text-danger">*</span></label>
											<input className="form-control" name="emergency_contact_number" type="number" onChange={handleInput} value={AddUserInput.emergency_contact_number} />
											<span style={styles.section}>{AddUserInput.error_list.emergency_contact_number}</span>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Source</label>
											<select className="form-control"  name="source_id" type="text" style={{width: "95%"}} onChange={handleChange} value={AddUserInput.source_id} >
											<option value="0">Select Source</option>
												{
													sourceData.map((source) => (
														<option key={source.id}  value={source.id}>
															 {source.name}
														</option>
													))
												}
											</select>
											<span style={styles.section}>{AddUserInput.error_list.source_id}</span>
										</div>
										<div className="input-group-append" style={{marginTop: "-60px", justifyContent: 'flex-end', marginBottom: '20px' }}>
        									<button type="button" className="btn btn-primary" style={{ height:'42px', width:'46px', color:'black', backgroundColor:'antiquewhite'}} title='Add Source' onClick={() => setsourceModal(true)}><i className="la la-plus-square"></i></button>
      									</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Bank Name <span className="text-danger">*</span></label>
											<select className="form-control" name="banck_name" type="text" onChange={handleChange} style={{width: "91%"}} value={AddUserInput.banck_name} >
											<option value="0">Select Bank Name</option>
												{
													bankData.map((bank) => (
														<option key={bank.id}  value={bank.id}>
															 {bank.name}
														</option>
													))
												}
											</select>
											<span style={styles.section}>{AddUserInput.error_list.banck_name}</span>
										</div>
										<div className="input-group-append" style={{marginTop: "-60px", justifyContent: 'flex-end'}}>
        									<button type="button" className="btn btn-primary" style={{ height:'42px', color:'black', backgroundColor:'antiquewhite' }} title='Add New Bank Name' onClick={() => setbankModal(true)}><i className="la la-plus-square"></i></button>
      									</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Payee Name <span className="text-danger">*</span></label>
											<input className="form-control" name="payee_name" type="text" onChange={handleInput} value={AddUserInput.payee_name} />
											<span style={styles.section}>{AddUserInput.error_list.payee_name}</span>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Account Number <span className="text-danger">*</span></label>
											<input className="form-control" name="account_number" type="number" onChange={handleInput} value={AddUserInput.account_number} />
											<span style={styles.section}>{AddUserInput.error_list.account_number}</span>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>IFSC Codes <span className="text-danger">*</span></label>
											<input className="form-control" name="ifsc_code" type="text" onChange={handleInput} value={AddUserInput.ifsc_code} />
											<span style={styles.section}>{AddUserInput.error_list.ifsc_code}</span>
										</div>
									</div>
								</div>
                                <br></br>
                                <h3><u>Official Details</u></h3><br/>
                                <div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label>Username <span className="text-danger">*</span></label>
											<input className="form-control" name="name" type="text" onChange={handleInput} value={AddUserInput.name} />
											<span style={styles.section}>{AddUserInput.error_list.name}</span>
										</div>
									</div>
                                </div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Date Of Joining <span className="text-danger">*</span></label>
											<div className="form-group">
                                                <input type="date" className="form-control" name="date_of_joining" onChange={handleChange} value={AddUserInput.date_of_joining} />
												<span style={styles.section}>{AddUserInput.error_list.date_of_joining}</span>
                                            </div>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Designation <span className="text-danger">*</span></label>
											<select className="form-control" name="designation" type="text" style={{width: "91%"}} onChange={handleChange} value={AddUserInput.designation} >
												<option value="0">Select Designation</option>
													{
														designationData.map((designation) => (
															<option key={designation.id}  value={designation.id}>
																{designation.name}
															</option>
														))
													}
											</select>
											<span style={styles.section}>{AddUserInput.error_list.designation}</span>
										</div>
										<div className="input-group-append" style={{marginTop: "-60px", justifyContent: 'flex-end'}}>
        										<button type="button" className="btn btn-primary" style={{ height:'42px', color:'black', backgroundColor:'antiquewhite' }} title='Add New Designation' onClick={() => setdesignationModal(true)}><i className="la la-plus-square"></i></button>
      									</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Department</label>
                                            <select className="form-control" name="department_id" style={{width: "91%"}} onChange={handleChange} value={AddUserInput.department_id} >
                                                <option value="0">Select Department</option>
												{
													departmentData.map((department) => (
														<option key={department.id}  value={department.id}>
															 {department.name}
														</option>
													))
												}
                                            </select>
											
											<div className="input-group-append" style={{marginTop: "-43px", justifyContent: 'flex-end'}}>
        										<button type="button" className="btn btn-primary" style={{ height:'42px', color:'black', backgroundColor:'antiquewhite' }} title='Add New Department' onClick={() => setmodal(true)}><i className="la la-plus-square"></i></button>
      										</div>
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Annual CTC</label>
											<input className="form-control" name="annual_ctc" type="number" onChange={handleInput} value={AddUserInput.annual_ctc} />
											<span style={styles.section}>{AddUserInput.error_list.annual_ctc}</span>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Role <span className="text-danger">*</span></label>
											<select className="form-control" name="roles" type="text" onChange={handleInput} style={{width: "91%"}} value={AddUserInput.roles} >
												<option value="0">Select User Role</option>
													{
															rolesData.map((roles) => (
																<option key={roles.id}  value={roles.id}>
																	{roles.name}
																</option>
															))
													}
											</select>
											<span style={styles.section}>{AddUserInput.error_list.roles}</span>   
										</div>
										<div className="input-group-append" style={{marginTop: "-60px", justifyContent: 'flex-end'}}>
        									<button type="button" className="btn btn-primary" style={{ height:'42px' , color:'black', backgroundColor:'antiquewhite'}} title='Add New Role' onClick={() => setroleModal(true)}><i className="la la-plus-square"></i></button>
      									</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Reporting Manager</label>
											<select className="form-control" name="reporting_manager_id" onChange={handleChange}  value={AddUserInput.reporting_manager_id}>
                                                <option value="0">Select Reporting Manager</option>
                                                {
														reportingManagerData.map((reportingManager) => (
															<option key={reportingManager.id}  value={reportingManager.fullname}>
																{reportingManager.fullname}
															</option>
														))
												}
                                            </select>
										</div>
									</div>
								</div>
                                <div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>Shift</label>
                                            <select className="form-control" name="shift_id" style={{width: "91%"}} onChange={handleChange} value={AddUserInput.shift_id}>
											<option value="0">Select Shift</option>
												{
													shiftData.map((shift) => (
														<option key={shift.id}  value={shift.id}>
															 {shift.name}
														</option>
													))
												}
                                            </select>
											<div className="input-group-append" style={{marginTop: "-43px", justifyContent: 'flex-end'}}>
        										<button type="button" className="btn btn-primary" style={{ height:'42px' , color:'black', backgroundColor:'antiquewhite'}} title='Add New Shift' onClick={() => setOpenModal(true)}><i className="la la-plus-square"></i></button>
												{/* <ShiftModal show={showLogin} close={() => setShowLogin(false)} /> */}
      										</div> 
										</div>
									</div>
                                    <div className="col-sm-6">
										<div className="form-group">
											<label>Attendance Card No <span className="text-danger">*</span></label>
											<input className="form-control" name="attendace_card_no" type="number" onChange={handleInput} value={AddUserInput.attendace_card_no} />
											<span style={styles.section}>{AddUserInput.error_list.attendance_card_no}</span>
										</div>
									</div>
								</div>
								<div className="submit-section">
									<button className="btn btn-primary submit-btn" type="submit" name="submit">Save</button>
								</div>
                                
							</form>
							{/* {modalOpen && <ShiftModal setOpenModal={setModalOpen} />} */}	
                        </div>
        </div>
        
    </div>
    )
}

export default AddUser;