import React from "react";
import {Link, useHistory} from 'react-router-dom';
import myImage from '../../assets/admin/assets/img/logo.png';
import userImage from '../../assets/admin/assets/img/profiles/avatar-01.jpg';
import axios from "axios";
import swal from "sweetalert";

function Navbar () {
    const history = useHistory();

    const logoutSubmit = (e) =>{
        e.preventDefault();

            axios.post(`/api/logout`).then(res => {

                if(res.data.status === 200){

                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_name');
                    swal("Success", res.data.message, "success");
                    history.push('/');
                }
            });
    }

    return (

        <div className="header">
        
            <div className="header-left">
                <Link to="index.php" className="logo">
                    <img src={myImage} width="40" height="40" alt="" />
                    
                </Link>
            </div>
        
            <Link id="toggle_btn" to="javascript:void(0);">
                <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </Link>

            <div className="page-title-box">
                <h3>Office Management System</h3>
            </div>
        
            <Link id="mobile_btn" className="mobile_btn" to="#sidebar"><i className="fa fa-bars"></i></Link>
        
            <ul className="nav user-menu">

                <li className="nav-item">
                    <div className="top-nav-search">
                        <Link to="javascript:void(0);" className="responsive-search">
                            <i className="fa fa-search"></i>
                        </Link>
                        <form action="search.php">
                            <input className="form-control" type="text" placeholder="Search here" />
                            <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                        </form>
                    </div>
                </li>

                <li className="nav-item dropdown">
                    <Link to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <i className="fa fa-bell-o"></i> <span className="badge badge-pill">3</span>
                    </Link>
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <span className="notification-title">Notifications</span>
                            <Link to="javascript:void(0)" className="clear-noti"> Clear All </Link>
                        </div>
                        <div className="noti-content">
                            <ul className="notification-list">
                                <li className="notification-message">
                                    <Link to="activities.php">
                                        <div className="media">
                                            <span className="avatar">
                                                <img alt="" src="assets/img/profiles/avatar-02.jpg" />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
                                                <p className="noti-time"><span className="notification-time">4 mins ago</span></p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="activities.php">
                                        <div className="media">
                                            <span className="avatar">
                                                <img alt="" src="assets/img/profiles/avatar-03.jpg" />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
                                                <p className="noti-time"><span className="notification-time">6 mins ago</span></p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="activities.php">
                                        <div className="media">
                                            <span className="avatar">
                                                <img alt="" src="assets/img/profiles/avatar-06.jpg" />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
                                                <p className="noti-time"><span className="notification-time">8 mins ago</span></p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="activities.php">
                                        <div className="media">
                                            <span className="avatar">
                                                <img alt="" src="assets/img/profiles/avatar-17.jpg" />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
                                                <p className="noti-time"><span className="notification-time">12 mins ago</span></p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="activities.php">
                                        <div className="media">
                                            <span className="avatar">
                                                <img alt="" src="assets/img/profiles/avatar-13.jpg" />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
                                                <p className="noti-time"><span className="notification-time">2 days ago</span></p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="activities.php">View all Notifications</Link>
                        </div>
                    </div>
                </li>
                
                <li className="nav-item dropdown">
                    <Link to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <i className="fa fa-comment-o"></i> <span className="badge badge-pill">8</span>
                    </Link>
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <span className="notification-title">Messages</span>
                            <Link to="javascript:void(0)" className="clear-noti"> Clear All </Link>
                        </div>
                        <div className="noti-content">
                            <ul className="notification-list">
                                <li className="notification-message">
                                    <Link to="chat.php">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">
                                                    <img alt="" src="assets/img/profiles/avatar-09.jpg" />
                                                </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Richard Miles </span>
                                                <span className="message-time">12:28 AM</span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="chat.php">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">
                                                    <img alt="" src="assets/img/profiles/avatar-02.jpg" />
                                                </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">John Doe</span>
                                                <span className="message-time">6 Mar</span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="chat.php">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">
                                                    <img alt="" src="assets/img/profiles/avatar-03.jpg" />
                                                </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Tarah Shropshire </span>
                                                <span className="message-time">5 Mar</span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="chat.php">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">
                                                    <img alt="" src="assets/img/profiles/avatar-05.jpg" />
                                                </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author">Mike Litorus</span>
                                                <span className="message-time">3 Mar</span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="chat.php">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar">
                                                    <img alt="" src="assets/img/profiles/avatar-08.jpg" />
                                                </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Catherine Manseau </span>
                                                <span className="message-time">27 Feb</span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="chat.php">View all Messages</Link>
                        </div>
                    </div>
                </li>
                
                <li className="nav-item dropdown has-arrow main-drop">
                    <Link to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <span className="user-img"><img src={userImage} alt="" />
                        <span className="status online"></span></span>
                        <span> Kajal</span>
                    </Link>
                    <div className="dropdown-menu">
                        <Link className="dropdown-item" to="profile.php">My Profile</Link>
                        <Link className="dropdown-item" to="settings.php">Settings</Link>
                        <Link className="dropdown-item" to="settings.php" onClick={logoutSubmit}>Logout</Link>
                    </div>
                </li>
            </ul>

            <div className="dropdown mobile-user-menu">
                <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></Link>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item" to="profile.php">My Profile</Link>
                    <Link className="dropdown-item" to="settings.php">Settings</Link>
                    <Link className="dropdown-item" to="settings.php">Logout123</Link>
                </div>
            </div>
        
        </div>
    );
}

export default Navbar;