import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
//import { Select2 } from "select2-react-component";
import Select from 'react-select';
import swal from 'sweetalert';

function Users() {

	const [loading, setLoading] = useState(true);
	//const [userlists, setUserlist] = useState([]);
	const [usersData, setData]  = useState([]);

	useEffect(() => {

		axios.get(`/api/userlist`).then(response =>{
			// console.log(response.data.users);
			if(response.status === 200){
				setData(response.data.users);
				setLoading(false);
			}
		});
	}, []);

	
	if(loading)
	{
		return <h4>Loading User List...</h4>
	}
	else{
		var viewuser_HTMLTABLE = "";
		
		viewuser_HTMLTABLE = usersData.map((item, index) => {
			return (
				<tr key={index}>
					<td>{item.name}</td>
					<td>{item.email}</td>
					<td>{item.personal_mobile_number}</td>
					<td>{item.designation.name}</td>
					<td>{item.roles.name}</td>
					<td className="text-right">
						<div className="dropdown dropdown-action">
							<Link to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
							<div className="dropdown-menu dropdown-menu-right">
								<Link className="dropdown-item" to={`edit-user/${item.id}`} data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</Link>
								<Link className="dropdown-item" to="#" data-toggle="modal" onClick={ (e) => deleteUsers(e, item.id) } data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</Link>
							</div>
						</div>
					</td>
				</tr>
			);
		});
	}

	const deleteUsers = (e, id) => {
		e.preventDefault();

		const thisClicked = e.currentTarget;
		thisClicked.innerText = "Deleting";

		axios.delete(`/api/delete-user/${id}`).then(res=>{
			if(res.data.status === 200){

				swal("Sucess", res.data.message, "success");
				thisClicked.closest("tr").remove();
			}
			else if(res.data.status === 404)
			{
				swal("Sucess", res.data.message, "success");
				thisClicked.innerText = "Delete";
			}
		});
	}

    return (
		
        <div className="page-wrapper">
			
                <div className="content container-fluid">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Users</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
									<li className="breadcrumb-item active">Users</li>
								</ul>
							</div>
							<div className="col-auto float-right ml-auto">
								<Link to="/admin/addUser" className="btn add-btn" data-toggle="modal" data-target="#add_user"><i className="fa fa-plus"></i> Add User</Link>
							</div>
						</div>
					</div>
	
					<div className="row filter-row">
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<input type="text" className="form-control floating" />
								<label className="focus-label">Name</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<Select className="select floating"> 
									<option>Select Company</option>
									<option>Global Technologies</option>
									<option>Delta Infotech</option>
								</Select>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<Select className="select floating"> 
									<option>Select Roll</option>
									<option>Web Developer</option>
									<option>Web Designer</option>
									<option>Android Developer</option>
									<option>Ios Developer</option>
								</Select>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<Link to="#" className="btn btn-success btn-block"> Search </Link>  
						</div>     
                    </div>
					
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								
							{/* <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
								<div class="row">
									<div class="col-sm-12 col-md-6">
										<div class="dataTables_length" id="DataTables_Table_0_length">
												<label>Show 
													<select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
														<option value="10">10</option>
														<option value="25">25</option>
														<option value="50">50</option>
														<option value="100">100</option>
													</select> entries
												</label>
										</div>
									</div>
								</div>
							</div> */}
								<table className="table table-striped custom-table datatable">
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Phone</th>
											<th>Designation</th>
											<th>Roles</th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										 {viewuser_HTMLTABLE}
									</tbody>
									
								</table>
							</div>
						</div>
					</div>
				
                </div>	
            </div>
    )
}

export default Users;