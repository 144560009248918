import Dashboard from '../components/admin/Dashboard';
// import Profile from '../components/admin/Profile';
import Users from '../components/admin/Users';
import AddUser from '../components/admin/AddUser';
import EditUser from '../components/admin/EditUser';
import StaffDashboard from '../components/admin/StaffDashboard';

const routes = [

    { path: '/admin', exact: true, name: 'Admin'},
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard},
    { path: '/admin/staffdashboard', exact: true, name: 'StaffDashboard', component: StaffDashboard},
    // { path: '/admin/profile', exact: true, name: 'Profile', component: Profile},
    { path: '/admin/users', exact: true, name: 'Users', component: Users},
    { path: '/admin/adduser', exact: true, name: 'AddUser', component: AddUser},
    { path: '/admin/edit-user/:id', exact: true, name: 'EditUser', component: EditUser},

];

export default routes;

