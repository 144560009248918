import React from "react";
import {Link} from 'react-router-dom';

function ForgorPassword () {

    return (

        <div className="main-wrapper">
		
			<div className="account-content">
				
				<div className="container">
				
					<div className="account-logo">
						<img src="/dnb.png" alt="DNB" style={{
                                    width: 200,
                                    height: 100,
                                    }} />
					</div>
					
					<div className="account-box">
						<div className="account-wrapper">
							<h3 className="account-title">Forgot Password?</h3>
							<p className="account-subtitle">Enter your email to get a password reset link</p>
							
							<form>
								<div className="form-group">
									<label>Email Address</label>
									<input className="form-control" type="text" />
								</div>
								<div className="form-group text-center">
									<button className="btn btn-primary account-btn" type="submit">Reset Password</button>
								</div>
								<div className="account-footer">
									<p>Remember your password? <Link to="/Login">Login</Link></p>
								</div>
							</form>
								
						</div>
					</div>
				</div>
			</div>
        </div>
    );

}

export default ForgorPassword;